import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

const MDXCustomRenderer = ({ data }) => (
  <MDXRenderer frontmatter={data.mdx.frontmatter}>{data.mdx.body}</MDXRenderer>
)

export default MDXCustomRenderer

export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      slug
      fields {
        crumbs {
          label
          path
        }
      }
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
